'use client';

import React from 'react';
import {
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
} from 'react-number-format';
import { Input, InputProps } from './Input';

export type NumberInputProps = Omit<
  InputProps,
  'defaultValue' | 'value' | 'type'
> &
  NumericFormatProps & {
    changeProperty?: keyof NumberFormatValues;
    ref?: InputProps['ref'];
  };

export const NumberInput: React.FC<NumberInputProps> = ({
  name,
  ref,
  ...props
}) => (
  <NumericFormat
    name={name}
    // @ts-expect-error It doesn't like the size prop...
    customInput={Input}
    inputMode="numeric"
    {...props}
  />
);
