'use client';

import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import { generateShopLink, ShopLinkProps } from '~/components/Links/ShopLink';

/**
 * This component will render a button that looks like and acts like a link; this is what the user interacts with.
 * The actual link will be hidden but should gain all the SEO benefits.
 */
interface Props extends Omit<ShopLinkProps, 'children'> {
  anchorText?: React.ReactNode;
  displayedText: React.ReactNode;
  onClick?: () => void;
}

const ShopLinkTitleHack: React.FC<Props> = ({
  anchorText,
  className,
  displayedText,
  onClick,
  ...props
}) => {
  const router = useRouter();
  const href = generateShopLink(props);

  // Fallback: if no anchor text is provided, just render a normal link
  if (!anchorText) {
    return (
      <span {...props} onClick={onClick}>
        {displayedText}
      </span>
    );
  }

  return (
    <>
      <span
        className={clsx(className, 'cursor-pointer')}
        onClick={() => {
          onClick?.();

          if (!href) {
            return;
          }

          if (props.internalRouter) {
            router.push(href);
          } else {
            window.location.assign(href);
          }
        }}
      >
        {displayedText}
      </span>
    </>
  );
};

export default ShopLinkTitleHack;
